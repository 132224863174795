.section_unisearch_student {
  padding-top: 100px;
  padding-bottom: 150px;
  background-image: url("/assets/images/home/slice-right.png");
  background-repeat: no-repeat;
  background-position: right;
}

.title_wrapper {
  margin-left: 100px;
  .sub_title {
    font-weight: normal;
    color: #828282;
    text-align: justify;
  }
  .title {
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    margin-top: 30px;
  }
}

.uni_wrapper {
  position: relative;
}

.oval {
  position: absolute;
  z-index: 0;
  left: -8%;
  top: -8%;
}
.square {
  position: absolute;
  z-index: 0;
  bottom: -8%;
  right: -15%;
}

.image_wrapper {
  position: relative;
  z-index: 1;
  img {
    width: 100%;
    // animation: moveAndScale 1s infinite alternate;
    // animation-timing-function: ease-in-out;
  }
}

// .oval {
//   animation: moverUpDown 1s infinite alternate;
// }

// .square {
//   animation: moverLeftRight 1s infinite alternate;
//   animation-delay: 0.1s;
// }

@keyframes moverUpDown {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moverLeftRight {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes moveAndScale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.01);
  }
}

@media (max-width: 1300px) {
  .oval {
    display: none;
  }
}

@media (max-width: 900px) {
  .section_unisearch_student {
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: unset;
  }
  .student_right {
    order: -1;
  }
  .title_wrapper {
    margin-left: 0px;
    max-width: 100%;
    .title {
      margin-top: 0;
    }
  }
  .square {
    display: none;
  }
}

@media (max-width: 600px) {
  .oval {
    display: none;
  }
}
